/* eslint-disable no-unused-vars */
import { z } from 'zod'

export const decodeTokenSchema = z.object({
  token_id: z.string(),
  phone_number: z.string().nullable(),
  receipt_sent: z.boolean().nullable(),
  receipt_url: z.string().nullable(),
  claim_id: z.string(),
  is_digitized: z.boolean().catch(false),
  used: z.boolean().nullable(),
  coverage_type: z.string(),
  merchant: z.string().nullable(),
  has_cash_advance: z.boolean().catch(false),
  coverage_percentage: z.number().catch(0),
  approved_amount: z.number().nullable(),
  recipient_name: z.string().nullable(),
  wave_account_active: z.boolean().catch(false),
  wave_phone_number: z.string().nullable(),
})

export enum CoverageTypeEnum {
  PRESCRIPTION = 'PRESCRIPTION',
  LAB_WORK = 'LAB_WORK',
  CONSULTATION = 'CONSULTATION',
}

export type DecodeToken = z.infer<typeof decodeTokenSchema>
