import { Box, Button, Group, Slider, Stack, Text } from '@mantine/core'
import { DocumentDownload } from 'iconsax-react'
import { Liquid } from 'liquidjs'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { numericFormatter } from 'react-number-format'
import { POCLimitFormSchemaType } from './POCLimitPlayground'
import proofOfCoverage from './proof_of_coverage.html?raw'

const engine = new Liquid()

export const POCTemplate = () => {
  const iframeRef = useRef<HTMLIFrameElement>(null)

  const [coverageSize, setCoverageSize] = useState(16)
  const { control } = useFormContext<POCLimitFormSchemaType>()

  const globalLimit = useWatch({ control, name: 'globalLimit' })
  const annualLimit = useWatch({ control, name: 'annualLimit' })
  const transactionLimit = useWatch({ control, name: 'transactionLimit' })
  const percentage = useWatch({ control, name: 'percentage' })
  const cashAdvance = useWatch({ control, name: 'cashAdvance' })
  const coverageType = useWatch({ control, name: 'coverageType' })

  const items = useWatch({ control, name: 'items' })

  const getCoverableAmount = useCallback(
    (
      item: POCLimitFormSchemaType['items'][number],
      globalLimit?: number,
      annualLimit?: number,
      transactionLimit?: number,
    ) => {
      if (item.excluded) return 0
      let coverableAmount = item.pricePerUnit * item.quantity

      if (typeof item.limitPerUnit === 'number' && item.pricePerUnit > item.limitPerUnit) {
        coverableAmount = item.limitPerUnit * item.quantity
      }

      if (
        coverageType === 'other' &&
        typeof item.limitPerTransaction === 'number' &&
        coverableAmount > item.limitPerTransaction
      ) {
        coverableAmount = item.limitPerTransaction
      }

      if (coverageType !== 'other' && typeof transactionLimit === 'number' && coverableAmount > transactionLimit) {
        coverableAmount = transactionLimit
      }

      if (coverageType === 'other' && typeof item.annualLimit === 'number' && coverableAmount > item.annualLimit) {
        coverableAmount = item.annualLimit
      }

      if (coverageType !== 'other' && typeof annualLimit === 'number' && coverableAmount > annualLimit) {
        coverableAmount = annualLimit
      }

      if (typeof globalLimit === 'number' && coverableAmount > globalLimit) {
        coverableAmount = globalLimit
      }

      return coverableAmount
    },
    [coverageType],
  )

  const getFormattedNumber = (value: number) => {
    if (isNaN(value) || value < 1) return '0'

    return numericFormatter(value?.toFixed(), {
      thousandSeparator: ' ',
    })
  }

  useEffect(() => {
    if (!iframeRef.current) return

    let updatedGlobalLimit = typeof globalLimit === 'number' ? globalLimit : undefined
    let updatedAnnualLimit = coverageType !== 'other' ? annualLimit : undefined
    let updatedTransactionLimit = coverageType !== 'other' ? transactionLimit : undefined

    let totalAmount = 0
    let totalCoverableAmount = 0

    const filteredItems = items
      .filter((item) => item.name)
      .map((item) => {
        const totalPrice = item.quantity * item.pricePerUnit
        const coverableAmount = getCoverableAmount(
          item,
          updatedGlobalLimit,
          updatedAnnualLimit,
          updatedTransactionLimit,
        )

        const exceededAmount = totalPrice - coverableAmount

        if (updatedGlobalLimit) {
          updatedGlobalLimit = Math.max(0, updatedGlobalLimit - coverableAmount)
        }
        if (updatedAnnualLimit) {
          updatedAnnualLimit = Math.max(0, updatedAnnualLimit - coverableAmount)
        }
        if (updatedTransactionLimit) {
          updatedTransactionLimit = Math.max(0, updatedTransactionLimit - coverableAmount)
        }

        totalAmount += totalPrice
        totalCoverableAmount += coverableAmount

        return {
          ...item,
          price: item.pricePerUnit
            ? `${getFormattedNumber(item.pricePerUnit)}${item.unitName ? ` / ${item.unitName}` : ''}`
            : '',
          amount: getFormattedNumber(totalPrice),
          coverable: getFormattedNumber(coverableAmount),
          exceeded: getFormattedNumber(exceededAmount),
        }
      })

    const insurancePay = cashAdvance ? totalAmount : (totalCoverableAmount * percentage) / 100
    const remainingAmount = totalCoverableAmount * ((100 - percentage) / 100) + (totalAmount - totalCoverableAmount)

    const renderedHtml = engine.parseAndRenderSync(proofOfCoverage, {
      remSize: `${coverageSize}px`,
      claimTotal: getFormattedNumber(totalAmount),
      totalCoverableAmount: getFormattedNumber(totalCoverableAmount),
      insurancePay: getFormattedNumber(insurancePay),
      remainingAmount: getFormattedNumber(remainingAmount),
      hasExceededAmount: totalAmount > totalCoverableAmount,
      percentage: percentage > 0 ? percentage.toFixed() : 0,
      cashAdvance,
      coverageType,
      items: filteredItems,
    })

    const doc = iframeRef.current.contentDocument

    if (doc) {
      if (import.meta.env.DEV) {
        iframeRef.current.setAttribute('srcDoc', renderedHtml)
        return
      }
      doc.open()
      doc.write(renderedHtml)
      doc.close()
    }

    return () => {
      doc?.open()
      doc?.write('')
      doc?.close()
    }
  }, [
    cashAdvance,
    coverageSize,
    coverageType,
    globalLimit,
    annualLimit,
    transactionLimit,
    items,
    percentage,
    getCoverableAmount,
  ])

  const downloadPdf = () => {
    const iframe = iframeRef.current
    if (!iframe) return

    const doc = iframe.contentDocument
    if (!doc) return

    const printWindow = window.open('', '', 'height=768,width=1024')
    printWindow?.document.write(doc.documentElement.outerHTML)
    printWindow?.document.close()
    printWindow?.print()
  }

  return (
    <Stack h={'100%'} pos="relative">
      <iframe
        ref={iframeRef}
        allowTransparency={true}
        className="flex-1 border-none ring-1 ring-gray-200 rounded-lg bg-white shadow-lg "
      />
      <Group justify="space-between">
        <Box>
          <Text size="sm">Font size</Text>
          <Slider w={200} min={12} max={24} value={coverageSize} onChange={(value) => setCoverageSize(value)} />
        </Box>
        <Button variant="light" color="alerts-green" leftSection={<DocumentDownload />} onClick={downloadPdf}>
          Download as PDF
        </Button>
      </Group>
    </Stack>
  )
}
