import WaveIcon from '@/components/icons/WaveIcon'
import { useOrganizationDetails } from '@/features/make-payment/api/getOrganizationDetails'
import { useDecodeToken } from '@/features/token/api/decode-token'
import { Badge, Button, Divider, Drawer, Group, NumberFormatter, rem, Stack, Text } from '@mantine/core'
import { useIsMutating } from '@tanstack/react-query'
import { TickCircle } from 'iconsax-react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMatch } from 'react-router-dom'
import { usePaymentBreakdown } from './usePaymentBreakdown'

export const ConfirmPaymentDrawer = ({
  opened,
  onClose,
  onConfirm,
}: {
  opened: boolean
  onClose: () => void
  onConfirm: (amount: number) => void
}) => {
  const isB2B = Boolean(useMatch('/payment/make-payment-b2b/:token'))

  const { t } = useTranslation()
  const { data: decodedToken } = useDecodeToken()

  const { data: organizationDetails } = useOrganizationDetails({
    queryConfig: {
      enabled: isB2B,
    },
  })
  const { control } = useFormContext()
  const amount = useWatch({ control, name: 'amount' })
  const phoneNumber = useWatch({ control, name: 'phone_number' })

  const isLoading = useIsMutating({ mutationKey: ['makePayment'] })

  const percentage = decodedToken?.coverage_percentage ?? 0
  const cashAdvance = decodedToken?.has_cash_advance ?? false

  const { coveredAmount, remainingAmount } = usePaymentBreakdown({
    percentage,
    cashAdvance,
    userInputAmount: amount,
  })

  if ((isB2B && !organizationDetails) || !decodedToken) return null

  return (
    <Drawer
      opened={opened}
      onClose={onClose}
      position="bottom"
      title={t('confirm_payment')}
      classNames={{
        inner: 'justify-center',
        content: 'h-auto rounded-t-3xl',
        title: 'font-bold text-lg',
        close: 'ring-1 ring-base-darkgray-6 rounded-full',
        header: 'pt-6',
      }}
      styles={{
        content: {
          maxWidth: rem(540),
        },
        close: {
          '--cb-size': '1.5rem',
          '--cb-icon-size': '0.75rem',
        },
      }}>
      <Stack mt={32} gap={24}>
        <Stack gap={12}>
          <Group justify="space-between">
            <Text data-autofocus size="sm">
              {isB2B ? t('merchant') : t('beneficiary')}
            </Text>
            {isB2B ? (
              <Badge variant="outline">{organizationDetails?.name}</Badge>
            ) : (
              <Badge variant="outline" color={'black'} bg="white">
                {decodedToken?.recipient_name}
              </Badge>
            )}
          </Group>
          {!isB2B && (
            <Group justify="space-between">
              <Text size="sm">{t('wave_number')}</Text>
              <Badge variant="outline">
                <Group gap={4}>
                  {!isB2B && (
                    <WaveIcon
                      style={{
                        width: rem(18),
                        height: rem(18.5),
                      }}
                    />
                  )}
                  <Text fz={14} fw={500} component="a" href="javascript: void(0)">
                    +{phoneNumber}
                  </Text>
                </Group>
              </Badge>
            </Group>
          )}
        </Stack>
        <Stack gap={12}>
          <Group justify="space-between">
            <Text size="sm">{t('coverage_rate')}</Text>
            <Badge color="black" variant="outline" className="!bg-white">
              {percentage}%
            </Badge>
          </Group>
          <Group justify="space-between">
            <Text size="sm">
              {t('sura_pays')} ({cashAdvance ? 100 : percentage}%)
            </Text>
            <Badge color="alerts-green" variant="outline">
              <NumberFormatter
                suffix=" F"
                value={coveredAmount}
                decimalScale={0}
                thousandSeparator
                className="font-bold"
              />
            </Badge>
          </Group>
        </Stack>
        <Divider variant="dashed" my={-8} />
        <Group wrap="nowrap" gap={28} justify="space-between">
          <Text size="sm" className="text-balance">
            {cashAdvance ? t('will_be_deducted_from_salary') : t('to_be_paid_on_site')} ({100 - percentage}%)
          </Text>
          <Badge color="alerts-red" variant="outline" className="shrink-0">
            <NumberFormatter suffix=" F" value={remainingAmount} decimalScale={0} thousandSeparator />
          </Badge>
        </Group>
        <Button
          mt={36}
          fullWidth
          styles={{
            root: {
              height: rem(55),
            },
          }}
          loading={!!isLoading}
          onClick={() => onConfirm(amount)}>
          <TickCircle />
          <Text ml={8} fw={700}>
            {t('validate')}
          </Text>
        </Button>
      </Stack>
    </Drawer>
  )
}
