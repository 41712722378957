import { SVGProps } from 'react'

const WaveIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={29} height={40} viewBox="0 0 29 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M21.3868 1.78059C23.5816 3.66556 24.1483 5.57674 24.5641 8.34692C24.6146 8.67794 24.6146 8.67794 24.6661 9.01565C25.0002 11.2533 25.2803 13.4975 25.5619 15.7422C25.8212 17.8047 26.0968 19.8608 26.4326 21.9125C26.4652 22.1114 26.4977 22.3104 26.5313 22.5154C26.6416 23.1838 26.7531 23.852 26.8664 24.5199C27.4364 27.9175 27.4959 31.187 25.5357 34.1576C25.3782 34.3702 25.2187 34.5816 25.0536 34.7885C25.021 34.8293 25.0142 34.8853 25.037 34.9323C25.256 35.3854 25.4672 35.7359 25.7973 36.1382C26.4485 36.9726 26.5838 37.4824 26.5939 38.5056C26.5946 38.5808 26.5336 38.642 26.4585 38.642C20.2995 38.642 14.1392 38.642 7.79625 38.642C7.72111 38.642 7.66009 38.5809 7.66012 38.5058C7.66061 37.0669 7.67713 37.044 8.23312 36.2752C8.33948 36.1236 8.44585 35.9721 8.55544 35.8159C8.82555 35.4898 8.85288 35.4568 9.1451 35.3087C9.20715 35.2773 9.23621 35.2043 9.2111 35.1394C8.94855 34.4612 8.63032 33.8534 8.25803 33.2233C7.09654 31.0873 7.15791 28.88 7.26066 26.5032C7.2612 26.4906 7.26351 26.478 7.26751 26.466C7.32964 26.2796 7.39183 26.0931 7.45575 25.9013C7.4591 25.8913 7.46127 25.8809 7.46219 25.8703C7.50076 25.4323 7.52318 24.9928 7.53553 24.5533C7.54513 24.3222 7.55473 24.0911 7.56461 23.8532C7.56495 23.8451 7.56454 23.8368 7.56338 23.8287C7.25668 21.6865 5.29007 20.2935 3.80548 18.8835C2.58823 17.7016 1.5262 16.6005 1.14616 14.9087C1.14394 14.8989 1.14284 14.8886 1.1429 14.8785C1.15281 13.2063 1.6322 11.9944 2.81595 10.7971C2.82515 10.7878 2.83596 10.7797 2.84747 10.7735C3.48454 10.4295 3.92663 10.4426 4.63725 10.535C4.65893 10.5378 4.68019 10.5461 4.6981 10.5587C5.22337 10.9264 5.23202 10.9351 5.74174 11.4487C6.64018 12.3223 7.56337 13.1065 8.56245 13.8571C8.61646 13.8977 8.69261 13.8926 8.73567 13.8406C9.45364 12.9725 9.3299 10.8733 9.44143 9.79192C9.75641 6.88299 10.0467 4.20385 12.3065 2.09357C15.0707 -0.0590869 18.4862 -0.295239 21.3868 1.78059Z"
      fill="#020505"
    />
    <path
      d="M18.025 16.5179C19.6357 17.0953 20.5559 18.1482 21.2638 19.6446C21.3665 19.8585 21.3665 19.8585 21.4713 20.0767C22.9346 23.2189 22.7147 26.8335 21.6126 30.071C20.8834 31.8744 19.8244 32.9904 18.2243 34.0572C16.9363 34.2549 15.8547 34.1557 14.7909 33.3806C13.0533 31.8076 12.0494 29.5922 11.8464 27.2806C11.8051 26.2144 11.8146 25.1581 11.8464 24.0917C11.85 23.8766 11.8536 23.6616 11.8573 23.44C11.9602 21.1313 12.8819 19.0023 14.5659 17.4023C15.7044 16.4272 16.5604 16.4036 18.025 16.5179Z"
      fill="white"
    />
    <path
      d="M24.2537 34.7686C25.2568 35.3412 25.9394 36.1579 26.4711 37.1727C26.5956 37.6461 26.5956 37.6461 26.5956 38.6427C23.8332 38.6427 21.0708 38.6427 18.2246 38.6427C18.6422 36.7636 18.9524 35.9032 20.4801 34.8519C21.605 34.1968 23.087 34.2425 24.2537 34.7686Z"
      fill="#E6791E"
    />
    <path
      d="M13.4912 34.7689C14.536 35.3632 15.3968 36.3219 15.8331 37.4472C15.8331 37.8418 15.8331 38.2364 15.8331 38.643C13.1364 38.643 10.4398 38.643 7.66138 38.643C7.66138 37.1012 7.85894 36.5553 8.85724 35.4541C10.2377 34.3208 11.8409 34.0459 13.4912 34.7689Z"
      fill="#E6791E"
    />
    <path
      d="M18.8471 9.52933C19.5691 9.79094 20.2574 10.008 21.0146 10.1397C20.6346 11.1622 19.8499 11.4468 18.9079 11.8907C17.6373 12.3407 16.3702 12.4164 15.0992 11.9273C14.6531 11.6874 14.2485 11.4344 13.8395 11.1363C13.6917 11.0348 13.544 10.9333 13.3918 10.8287C13.2764 10.7329 13.1611 10.6371 13.0422 10.5383C13.0422 10.4068 13.0422 10.2752 13.0422 10.1397C13.5439 9.97108 14.0463 9.80548 14.5495 9.64144C14.6899 9.59391 14.8303 9.54638 14.975 9.49741C16.36 9.05065 17.4619 9.02117 18.8471 9.52933Z"
      fill="#E6791E"
    />
    <path
      d="M14.7482 5.7931C15.2341 5.95504 15.2341 5.95504 15.6327 6.35366C15.6825 6.95159 15.6825 6.95159 15.6327 7.54952C15.2341 7.94814 15.2341 7.94814 14.6361 8.01042C14.4388 7.98987 14.2415 7.96931 14.0382 7.94814C13.6087 7.30386 13.5462 7.10077 13.6396 6.35366C13.9783 5.81877 14.1107 5.74645 14.7482 5.7931Z"
      fill="white"
    />
    <path
      d="M19.5321 5.7931C20.018 5.95504 20.018 5.95504 20.4166 6.35366C20.4664 6.95159 20.4664 6.95159 20.4166 7.54952C20.018 7.94814 20.018 7.94814 19.5321 8.01042C19.0214 7.94814 19.0214 7.94814 18.6602 7.71146C18.3387 7.22084 18.3371 6.92953 18.4235 6.35366C18.7622 5.81877 18.8946 5.74645 19.5321 5.7931Z"
      fill="white"
    />
  </svg>
)
export default WaveIcon
