import { Container, Grid, ScrollArea } from '@mantine/core'
import { FormProvider, useForm } from 'react-hook-form'
import { POCForm } from './POCForm'
import { POCTemplate } from './POCTemplate'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'

const POCLimitFormSchema = z.object({
  coverageType: z.string(),
  percentage: z.number(),
  cashAdvance: z.boolean(),
  isDigitized: z.boolean(),
  userInputAmount: z.number(),
  claimTotal: z.number(),
  globalLimit: z.number(),
  transactionLimit: z.number().optional(),
  annualLimit: z.number().optional(),
  items: z.array(
    z.object({
      name: z.string(),
      unitName: z.string(),
      quantity: z.number(),
      pricePerUnit: z.number(),
      limitPerUnit: z.number().optional(),
      annualLimit: z.number().optional(),
      limitPerTransaction: z.number().optional(),
      excluded: z.boolean(),
    }),
  ),
})

export type POCLimitFormSchemaType = z.infer<typeof POCLimitFormSchema>

export const POCLimitPlayground = () => {
  const form = useForm<POCLimitFormSchemaType>({
    defaultValues: {
      coverageType: 'prescription',
      percentage: 80,
      cashAdvance: true,
      isDigitized: true,
      userInputAmount: 1020,
      claimTotal: 1000,
      globalLimit: 100000,
      items: [
        {
          name: 'General Consultation',
          unitName: '',
          quantity: 1,
          pricePerUnit: 50000,
          excluded: false,
        },
      ],
    },
    resolver: zodResolver(POCLimitFormSchema),
  })

  return (
    <FormProvider {...form}>
      <Container py={12} maw={'100%'}>
        <Grid justify="center" gutter={32}>
          <Grid.Col span={5} h={'100vh'}>
            <ScrollArea h={'100%'}>
              <POCForm />
            </ScrollArea>
          </Grid.Col>
          <Grid.Col span={5} h={'100vh'}>
            <POCTemplate />
          </Grid.Col>
        </Grid>
      </Container>
    </FormProvider>
  )
}
