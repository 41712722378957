/* eslint-disable @typescript-eslint/no-explicit-any */
import { MantineColorsTuple } from '@mantine/core'

export const colors: Record<string, MantineColorsTuple> = {
  'base-blue': [
    '#B3C3FE',
    '#cedaff',
    '#9cb1ff',
    '#6685fd',
    '#3a60fc',
    '#1e49fb',
    '#0c3dfc',
    '#0030e1',
    '#002aca',
    '#0023b2',
  ],
  'alerts-red': [
    '#E6B2BB',
    '#FF7A90',
    '#FF516E',
    '#FF284C',
    '#FF002B',
    '#D60024',
    '#AD001D',
    '#8C0017',
    '#3D000A',
    '#050001',
  ],
  'alerts-green': [
    '#B2D0B9',
    '#31FF5C',
    '#08FF3C',
    '#00DE2F',
    '#00B626',
    '#008D1E',
    '#006415',
    '#005111',
    '#000000',
    '#000000',
  ],
  'alerts-yellow': [
    '#FEEFDC',
    '#EFAA61',
    '#EDA04F',
    '#EA8C2A',
    '#D57815',
    '#B06312',
    '#8B4E0E',
    '#583109',
    '#251504',
    '#000000',
  ],
  'base-gray': ['#FAFAFA'] as any,
  'base-darkgray': [
    '#C6C9D3',
    '#BBBECA',
    '#A4A8B8',
    '#8D93A6',
    '#767D94',
    '#63697F',
    '#515668',
    '#383C48',
    '#202229',
    '#070809',
  ],
  'base-black': [
    '#FFFFFF',
    '#F0EFED',
    '#C8C1BA',
    '#9F9387',
    '#70655A',
    '#3D3731',
    '#0A0908',
    '#000000',
    '#000000',
    '#000000',
  ],
  black: ['#0A0908'] as any,
  'bg-base-gray': ['#FAFAFA'] as any,
  'stroke-base-gray': ['#E6E6E6'] as any,
  'secondary-base-blue': ['#EAEEFE'] as any,
  'secondary-alerts-green': ['#E6EDEA'] as any,
  'secondary-alerts-red': ['#F3E8E7'] as any,
  'text-disabled-base-black': ['#B5B5B5'] as any,
  'text-default-base-gray': ['#515668'] as any,
}
